import { api } from '.';
import { BLOCK_LIST_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

/**
 *
 * @param {String} clientId
 * @param {QueryParamsBuilder} params
 * @return {Promise<{data: BlockedElement[], meta:EndpointMeta}>}
 */
export async function getBlockList(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [BLOCK_LIST_RESOURCE]: undefined,
  });
  params.addFilter('client.id', clientId);
  params.all = true;
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
