var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-10" },
    [
      !_vm.isLoading
        ? _c(
            "card-form",
            [
              _c("template", { slot: "title" }, [_vm._v("URL")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _vm.offer
                    ? _c("url-creator", {
                        staticClass: "w-full",
                        attrs: {
                          label: "Offer URL",
                          options: _vm.urlCreatorOptions,
                          "text-error": _vm.urlError,
                          error: !_vm.isUrlValid,
                          required: "",
                          "show-details": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "info-popover-content",
                              fn: function () {
                                return [
                                  _c("macro-info", {
                                    attrs: {
                                      "macro-descriptions":
                                        _vm.macroDescriptions,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1087835806
                        ),
                        model: {
                          value: _vm.offer.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.offer, "url", $$v)
                          },
                          expression: "offer.url",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _c("div", [_c("card-form-loading", { staticClass: "mt-10" })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }