<template>
  <div>
    <card-form v-if="!isLoading && !isLoadingContent">
      <template slot="title">Basic Info</template>
      <template slot="form">
        <form-row>
          <template #left>
            <sun-label-group text="Name">
              <asterix-input
                id="name"
                v-model="offer.name"
                type="text"
                name="name"
                validate-on-blur
                :minlength="3"
                :maxlength="250"
                text-error="This field is required."
                :error="!!formErrors.name"
                placeholder="My new offer..."
                required="required"
                @change="formErrors.name = null"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Advertiser">
              <advertiser-filter
                v-model="offer.advertiser"
                :client-id="activeClient ? activeClient.id : ''"
                :service="getAdvertisers"
                required="required"
                style="width: 100%"
                text-error="This field is required."
                :error="!!formErrors.advertiser"
                :disabled="isEditOffer || isAdvertiserInUrl"
                @change="addAdvertiser($event)"
              />
            </sun-label-group>
          </template>
        </form-row>

        <form-row>
          <template #left>
            <sun-label-group text="Country">
              <sun-select
                v-model="offer.country"
                :options="countries"
                track-by="id"
                label="name"
                required="required"
                text-error="This field is required."
                close-on-select
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Status">
              <status-select :status="status" :status-options="statusOptions" @change="updateStatus" />
            </sun-label-group>
          </template>
        </form-row>
        <form-row>
          <template #left>
            <div class="flex justify-between">
              <sun-label-group text="Start date" class="w-full mr-2">
                <sun-date-picker
                  :value="{ startDate: minDate }"
                  single-date-picker
                  :max-date="maxDate"
                  :min-date="yesterday"
                  name="startDate"
                  class="w-full"
                  :class-input="offerDateStartClass"
                  required="required"
                  text-error="This field is required."
                  @change="onNewStartDate"
                />
              </sun-label-group>
              <div class="w-full">
                <sun-label-group text="Start time">
                  <asterix-time-picker
                    v-model="offerTime.start"
                    :custom-class="offerTimeStartClass"
                    @change="onNewStartTime"
                  />
                </sun-label-group>
              </div>
            </div>
          </template>
          <template #right>
            <div class="flex justify-between">
              <sun-label-group text="End date" class="w-full mr-2">
                <sun-date-picker
                  :key="`${endDateKey}-end-date`"
                  :value="{ startDate: maxDate }"
                  single-date-picker
                  :min-date="offer.start"
                  name="endDate"
                  class="w-full"
                  :class-input="offerDateEndClass"
                  text-error="This field is required."
                  @change="onNewEndDate"
                />
              </sun-label-group>
              <sun-label-group text="End time" class="w-full">
                <div class="flex flex-col w-full">
                  <asterix-time-picker
                    :key="`${endDateKey}-end-time`"
                    v-model="offerTime.end"
                    :disabled="!offer.end"
                    :custom-class="offerTimeEndClass"
                    @change="onNewEndTime"
                  />
                  <sun-error-text :show-error="!isDateValid" text-error="End date must be greater than start date" />
                </div>
              </sun-label-group>
              <button v-if="offer.end" role="button" class="absolute bottom-0 right-0 p-2" @click.stop="onResetEndDate">
                <close-svg class="w-5 h-5 p-1 text-gray-600" />
              </button>
            </div>
          </template>
        </form-row>

        <form-row>
          <template #left>
            <sun-label-group text="Campaign">
              <campaign-filter
                v-model="offer.campaign"
                :client-id="activeClient ? activeClient.id : ''"
                :service="getCampaigns"
                style="width: 100%"
                :disabled="!isNewOffer"
                @change="addCampaign"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Tags">
              <sun-select
                v-model="offer.tags"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                label="name"
                track-by="id"
                :options="tagList"
                :loading="!tagList"
                :text-error="errorTags"
                :error="!!errorTags"
                multiple
                taggable
                color-tag="gray"
                add-hex-color="orange"
                @tag="addTag"
              />
            </sun-label-group>
          </template>
        </form-row>
        <form-row>
          <template #left>
            <asterix-input-and-show
              label-title="Pixel code"
              :value="offerPixel"
              :cursor-not-allowed="!offerPixel"
              :disabled="!offerPixel"
              @open="openDialog('offer')"
            />
          </template>
          <template #right>
            <asterix-input-and-show
              label-title="Campaign pixel code"
              :value="campaignPixel"
              :cursor-not-allowed="!campaignPixel"
              :disabled="!campaignPixel"
              @open="openDialog('campaign')"
            />
          </template>
        </form-row>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading :rows="6"></card-form-loading>
    </div>

    <asterix-modal v-if="showPixelModal" :title="pixelModalTitle">
      <template #content>
        <div class="mb-8 mx-2 w-full pr-2">
          <sun-label-group text="Image">
            <asterix-input-and-copy class="w-full" :value="pixelIMG" detail-classes="mt-0" />
          </sun-label-group>
          <sun-label-group text="Javascript" class="mt-2">
            <asterix-input-and-copy class="w-full" :value="pixelJS" detail-classes="mt-0" />
          </sun-label-group>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap justify-end between sm:px-3">
          <sun-button
            variant="pill"
            color="white"
            class="custom-p-1 text-sm hover:bg-gray-100"
            @click="showPixelModal = false"
          >
            Close
          </sun-button>
        </div>
      </template>
    </asterix-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { CURRENCIES } from '@/store/modules/currency/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { CONTEXT_NS, ACTIVE_CLIENT_KEY } from '@/store/modules/context/keys';
import { getAdvertisers } from '@/services/modules/ecommerce/advertiser';
import { getCampaigns } from '@/services/modules/ecommerce/campaign';
import { getTags } from '@/services/modules/ecommerce/tag/getTags';
import Tag from '@/entities/ecommerce/Tag';
import { stringToDate } from '@/filters/dateFilters';
import { TODAY } from '@/utils/dateTime/today';
import { addDays } from '@/utils/dateTime/addDays';
import { dateCompare } from '@/model/shared/SortOption';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode.js';
import CardForm from '@/components/atoms/CardForm';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder.js';
import AsterixTimePicker from '@/components/atoms/AsterixTimePicker';
import AdvertiserFilter from '@/components/molecules/shared/filters/AdvertiserFilter';
import CampaignFilter from '@/components/molecules/shared/filters/CampaignFilter';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import getTimeString from '@/utils/dateTime/getTimeString.js';
import CONFIG from '@/components/molecules/modules/ecommerce/offer/form/basicInfo/config.js';
import AsterixInputAndCopy from '@/components/atoms/AsterixInputAndCopy';
import AsterixInputAndShow from '@/components/atoms/AsterixInputAndShow';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import createPixelTag from '@/utils/createPixelTag';
import createPixelJSTag from '@/utils/createPixelJSTag';
import StatusSelect from '@/components/atoms/StatusSelect';

export default {
  name: 'OfferBasicInfo',
  components: {
    AsterixTimePicker,
    AdvertiserFilter,
    CampaignFilter,
    CardForm,
    FormRow,
    AsterixInput,
    AsterixInputAndCopy,
    AsterixInputAndShow,
    CardFormLoading,
    StatusSelect,
    CloseSvg: () => import('@/components/icons/CloseSvg.vue'),
    AsterixModal: () => import('@/components/organisms/shared/AsterixModal'),
  },
  props: {
    baseOffer: {
      type: Object,
      default: () => null,
    },
    viewMode: {
      type: String,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      offer: null,
      statusOptions: CONFIG.statusOptions,
      yesterday: addDays(TODAY, -1),
      today: TODAY,
      offerTime: { start: '', end: '' },
      tagList: [],
      campaignSelected: null,
      endDateKey: 0,
      formErrors: {
        name: '',
        advertiser: null,
        url: null,
        percentage: null,
        currency: null,
      },
      isLoadingContent: true,
      showPixelModal: false,
      pixelIMG: 'N/A',
      pixelJS: 'N/A',
      pixelModalTitle: '',
    };
  },
  computed: {
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    ...mapState(CONTEXT_NS, {
      activeClient: ACTIVE_CLIENT_KEY,
    }),
    ...mapGetters({
      currencyList: CURRENCIES,
    }),
    isNewOffer() {
      return this.viewMode === 'create';
    },
    isEditOffer() {
      return this.viewMode === 'edit';
    },
    isAdvertiserInUrl() {
      return !!this.$route.query.advertiserId;
    },
    minDate() {
      return this.offer.start ? stringToDate(this.offer.start) : this.today;
    },
    maxDate() {
      return this.offer.end ? stringToDate(this.offer.end) : null;
    },
    errorTags() {
      return this.offer.tags.length > 5 ? 'Maximum 5 tags' : '';
    },
    status: {
      get() {
        return this.statusOptions.find(({ value }) => value === this.offer.status);
      },
      set(status) {
        if (status?.value) {
          this.offer.status = status.value;
        }
      },
    },
    isDateValid() {
      const datePassed = dateCompare(this.offer.start, this.offer.end) < 0;
      return datePassed || !this.offer.end;
    },
    isBeforeDateStarted() {
      return stringToDate(this.offer.start) > new Date();
    },
    isAfterDateFinished() {
      return stringToDate(this.offer.end) < new Date();
    },
    offerDateStartClass() {
      return this.status === this.statusOptions[0] && this.isBeforeDateStarted ? 'border-yellow-400' : '';
    },
    offerTimeStartClass() {
      return this.status === this.statusOptions[0] && this.isBeforeDateStarted ? 'offerTimeStart' : '';
    },
    offerDateEndClass() {
      return this.status === this.statusOptions[2] && this.isAfterDateFinished ? 'border-red-400' : '';
    },
    offerTimeEndClass() {
      return this.status === this.statusOptions[2] && this.isAfterDateFinished ? 'offerTimeEnd' : '';
    },
    offerPixel() {
      return this.offer.pixel || '';
    },
    campaignPixel() {
      return this.offer.campaign?.pixel || '';
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
  },
  created() {
    this.loadOffer();
    this.getTags();
    this.isLoadingContent = false;
  },
  methods: {
    ...mapActions({
      getCountries: GET_COUNTRIES_REQUEST,
      createToast: CREATE_TOAST,
    }),
    loadOffer() {
      this.offer = this.baseOffer;
      if (this.offer.start) {
        this.offerTime.start = getTimeString(stringToDate(this.offer.start));
      } else {
        this.offerTime.start = '00:00';
      }

      if (this.offer.end) {
        this.offerTime.end = getTimeString(stringToDate(this.offer.end));
      }
    },
    getAdvertisers(params = new QueryParamsBuilder()) {
      params.addInclude('activeOffersCount', 'category', 'subcategory');
      delete params.include.platform;
      return getAdvertisers(params);
    },
    getCampaigns,
    async getTags() {
      try {
        const { data } = await getTags();
        this.tagList = data;
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      }
    },
    addTag(text) {
      if (text.length <= 12) {
        const tag = new Tag(Date.now(), text);
        this.offer.tags.push(tag);
        this.tagList.push(tag);
      } else {
        this.createToast(Toast.error('Error!', 'Tags must have less than 13 characters'));
      }
    },
    addCampaign(campaign) {
      this.offer.campaign = campaign;
    },
    addAdvertiser(advertiser) {
      this.offer.advertiser = advertiser;
    },
    onNewStartDate({ value }) {
      this.$set(this.offer, 'start', value.startDate);
      this.onNewStartTime();
    },
    onNewStartTime() {
      this.offer.start = this.addTimeToDate(this.offer.start, this.offerTime.start);
    },
    onNewEndDate({ value }) {
      this.$set(this.offer, 'end', value.endDate);
      this.onNewEndTime();
    },
    onNewEndTime() {
      this.offer.end = this.addTimeToDate(this.offer.end, this.offerTime.end);
    },
    addTimeToDate(date, time) {
      const updated = stringToDate(date);
      let [hour, minutes] = time.split(':');
      hour = parseInt(hour);
      minutes = parseInt(minutes);

      hour = !isNaN(hour) ? hour : 0;
      minutes = !isNaN(minutes) ? minutes : 0;

      updated.setHours(hour, minutes);

      return updated;
    },
    onResetEndDate() {
      this.$set(this.offer, 'end', null);
      this.$set(this.offerTime, 'end', '');
      this.endDateKey++;
    },
    updateStatus(status) {
      status ? (this.offer.status = status.value) : (this.offer.status = null);
    },
    openDialog(codeType) {
      if (codeType === 'offer') {
        this.pixelModalTitle = 'Offer Pixel Code';
        this.pixelIMG = createPixelTag(this.offer.pixel);
        this.pixelJS = createPixelJSTag(this.offer.pixel);
      } else if (codeType === 'campaign') {
        this.pixelModalTitle = 'Campaign Pixel Code';
        this.pixelIMG = createPixelTag(this.offer.campaign.pixel);
        this.pixelJS = createPixelJSTag(this.offer.campaign.pixel);
      }

      this.showPixelModal = true;
    },
  },
};
</script>
<style scoped>
.offerTimeStart {
  @apply border-yellow-400;
}
.offerTimeEnd {
  @apply border-red-400;
}
</style>
