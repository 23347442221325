<template>
  <div>
    <card-form v-if="!isLoading" class="mt-10">
      <template slot="title">Attribution</template>
      <template slot="form">
        <form-row>
          <template #left>
            <form-column>
              <template #up>
                <div class="flex items-center">
                  <sun-label-group text="Postclick">
                    <sun-toggle
                      v-model="offer.offerAttribution.postclick_active"
                      class="ml-2"
                      name="booked-volume-toggle"
                      disabled
                    />
                  </sun-label-group>
                  <sun-label-group text="Length of time" class="flex-1 ml-8">
                    <sun-select
                      v-model="lengthOfTimeForPostclick"
                      :options="lengthOfTimeOptions"
                      track-by="value"
                      label="name"
                      close-on-select
                    />
                  </sun-label-group>
                </div>
              </template>
              <template #down>
                <div class="flex items-center">
                  <sun-label-group text="Postview">
                    <sun-toggle
                      v-model="offer.offerAttribution.postview_active"
                      class="ml-2"
                      name="attribution-postview-toggle"
                    />
                  </sun-label-group>
                  <sun-label-group v-show="offer.offerAttribution" text="Length of time" class="flex-1 ml-8">
                    <sun-select
                      v-model="lengthOfTimeForPostview"
                      :disabled="!offer.offerAttribution.postview_active"
                      :options="lengthOfTimeOptions"
                      track-by="value"
                      label="name"
                      close-on-select
                    />
                  </sun-label-group>
                </div>
              </template>
            </form-column>
          </template>
          <template #right>
            <div class="sm:border-t-2 sm:pt-4 lg:border-l-2 lg:border-t-0 lg:pl-8 lg:pt-0">
              <form-column>
                <template #up>
                  <sun-label-group text="Attribution time min">
                    <sun-select
                      :value="offerMinTime"
                      :options="attrTimeMinOption"
                      label="name"
                      track-by="value"
                      close-on-select
                      required="required"
                      text-error="This field is required"
                      @input="onMinTimeChange"
                    />
                  </sun-label-group>
                </template>
                <template #down>
                  <sun-label-group text="Attribution time out">
                    <sun-select
                      :value="offerMaxTime"
                      :options="attrTimeOutOption"
                      label="name"
                      track-by="value"
                      close-on-select
                      required="required"
                      text-error="This field is required"
                      @input="onMaxTimeChange"
                    />
                  </sun-label-group>
                </template>
              </form-column>
            </div>
          </template>
        </form-row>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading class="mt-10"></card-form-loading>
    </div>
  </div>
</template>
<script>
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import FormColumn from '@/components/atoms/FormColumn/FormColumn';
import CONFIG from '@/components/molecules/modules/ecommerce/offer/form/attribution/config';
import CardFormLoading from '@/components/atoms/CardFormLoading';

export default {
  name: 'OfferAttribution',
  components: {
    CardForm,
    FormRow,
    CardFormLoading,
    FormColumn,
  },
  props: {
    baseOffer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      offer: null,
      lengthOfTimeOptions: CONFIG.lengthOfTimeOptions,
      attrTimeMinOption: CONFIG.attributionTimeMin,
      attrTimeOutOption: CONFIG.attributionTimeOut,
    };
  },
  computed: {
    lengthOfTimeForPostclick: {
      get() {
        const { postclick_value } = this.offer.offerAttribution;
        return CONFIG.lengthOfTimeOptions.find(({ value }) => value === postclick_value);
      },
      set({ value }) {
        this.offer.offerAttribution.postclick_value = value || 0;
      },
    },
    lengthOfTimeForPostview: {
      get() {
        const { postview_value } = this.offer.offerAttribution;
        return CONFIG.lengthOfTimeOptions.find(({ value }) => value === postview_value);
      },
      set({ value }) {
        this.offer.offerAttribution.postview_value = value || 0;
      },
    },
    offerMaxTime() {
      return this.attrTimeOutOption.find(opt => opt.value === this.offer.offerAttribution.maxAttributionTime);
    },
    offerMinTime() {
      return this.attrTimeMinOption.find(opt => opt.value === this.offer.offerAttribution.minAttributionTime);
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
  },
  created() {
    this.loadOffer();
  },
  methods: {
    loadOffer() {
      this.offer = this.baseOffer;
    },
    onMaxTimeChange(event) {
      if (event) {
        this.offer.offerAttribution.maxAttributionTime = event.value;
      } else {
        this.offer.offerAttribution.maxAttributionTime = null;
      }
    },
    onMinTimeChange(event) {
      if (event) {
        this.offer.offerAttribution.minAttributionTime = event.value;
      } else {
        this.offer.offerAttribution.minAttributionTime = null;
      }
    },
  },
};
</script>
