var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "card-form",
            { staticClass: "mt-10" },
            [
              _c("template", { slot: "title" }, [_vm._v("Attribution")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c("form-column", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "up",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: { text: "Postclick" },
                                                },
                                                [
                                                  _c("sun-toggle", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      name: "booked-volume-toggle",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.offer
                                                          .offerAttribution
                                                          .postclick_active,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.offer
                                                            .offerAttribution,
                                                          "postclick_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "offer.offerAttribution.postclick_active",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "sun-label-group",
                                                {
                                                  staticClass: "flex-1 ml-8",
                                                  attrs: {
                                                    text: "Length of time",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.lengthOfTimeOptions,
                                                      "track-by": "value",
                                                      label: "name",
                                                      "close-on-select": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lengthOfTimeForPostclick,
                                                      callback: function ($$v) {
                                                        _vm.lengthOfTimeForPostclick =
                                                          $$v
                                                      },
                                                      expression:
                                                        "lengthOfTimeForPostclick",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "down",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c(
                                                "sun-label-group",
                                                { attrs: { text: "Postview" } },
                                                [
                                                  _c("sun-toggle", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      name: "attribution-postview-toggle",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.offer
                                                          .offerAttribution
                                                          .postview_active,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.offer
                                                            .offerAttribution,
                                                          "postview_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "offer.offerAttribution.postview_active",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "sun-label-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.offer
                                                          .offerAttribution,
                                                      expression:
                                                        "offer.offerAttribution",
                                                    },
                                                  ],
                                                  staticClass: "flex-1 ml-8",
                                                  attrs: {
                                                    text: "Length of time",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.offer
                                                          .offerAttribution
                                                          .postview_active,
                                                      options:
                                                        _vm.lengthOfTimeOptions,
                                                      "track-by": "value",
                                                      label: "name",
                                                      "close-on-select": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lengthOfTimeForPostview,
                                                      callback: function ($$v) {
                                                        _vm.lengthOfTimeForPostview =
                                                          $$v
                                                      },
                                                      expression:
                                                        "lengthOfTimeForPostview",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1768345014
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sm:border-t-2 sm:pt-4 lg:border-l-2 lg:border-t-0 lg:pl-8 lg:pt-0",
                                },
                                [
                                  _c("form-column", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "up",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: {
                                                    text: "Attribution time min",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      value: _vm.offerMinTime,
                                                      options:
                                                        _vm.attrTimeMinOption,
                                                      label: "name",
                                                      "track-by": "value",
                                                      "close-on-select": "",
                                                      required: "required",
                                                      "text-error":
                                                        "This field is required",
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onMinTimeChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "down",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: {
                                                    text: "Attribution time out",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      value: _vm.offerMaxTime,
                                                      options:
                                                        _vm.attrTimeOutOption,
                                                      label: "name",
                                                      "track-by": "value",
                                                      "close-on-select": "",
                                                      required: "required",
                                                      "text-error":
                                                        "This field is required",
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onMaxTimeChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1528630142
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3823303923
                    ),
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _c("div", [_c("card-form-loading", { staticClass: "mt-10" })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }