<template>
  <vue-timepicker
    class="asterix-timepicker"
    :class="[{ 'cursor-not-allowed': disabled }, customClass]"
    :value="value"
    input-width="100%"
    :format="format"
    :hide-clear-button="hideClearButton"
    :close-on-complete="closeOnComplete"
    :disabled="disabled"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  name: 'AsterixTimePicker',
  components: {
    VueTimepicker,
  },
  props: {
    value: {
      type: [String, Object],
      default: () => null,
    },
    format: {
      type: String,
      default: () => 'HH:mm',
    },
    hideClearButton: {
      type: Boolean,
      default: () => true,
    },
    closeOnComplete: {
      type: Boolean,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    customClass: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style scoped>
.asterix-timepicker {
  @apply block shadow appearance-none border rounded border-gray-300 text-gray-700 h-10;
}

::v-deep input.display-time:focus {
  @apply outline-none shadow-outline;
}

::v-deep input.display-time {
  @apply border-none rounded h-full;
}
input:disabled {
  cursor: not-allowed !important;
}

::v-deep .dropdown.drop-down .select-list .active {
  @apply bg-orange-500;
}

::v-deep .dropdown.drop-down .select-list .active:hover {
  @apply bg-orange-600;
}
</style>
