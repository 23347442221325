<template>
  <sun-label-group :text="labelTitle">
    <div class="flex w-full flex-col">
      <div class="flex w-full">
        <sun-input disabled :value="value || 'N/A'" class-input="shadow-r-none border-r-none rounded-r-none" />
      </div>
    </div>
    <button
      type="button"
      class="hover:bg-gray-400 action-button align-bottom p-2 shadow border py-2 px-3 rounded h-10 border-gray-300 rounded-l-none shadow-l-none border-l-none"
      :class="{ 'cursor-not-allowed': cursorNotAllowed }"
      :disabled="disabled"
      @click="openDialog()"
    >
      <eye-svg class="w-4 h-4" />
    </button>
  </sun-label-group>
</template>
<script>
import EyeSvg from '@/components/icons/EyeSvg';

export default {
  name: 'AsterixInputAndShow',
  components: {
    EyeSvg,
  },
  props: {
    labelTitle: { type: String, default: () => '' },
    value: { type: String, default: () => '' },
    cursorNotAllowed: { type: Boolean, default: () => false },
    disabled: { type: Boolean, default: () => false },
  },
  methods: {
    openDialog() {
      this.$emit('open');
    },
  },
};
</script>
