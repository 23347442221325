<template>
  <asterix-async-select
    id="campaign-select"
    name="select-campaign"
    class="campaign-filter"
    add-hex-color="orange"
    :value="value"
    class-input="pt-0 shadow-none rounded-none multiselect"
    :text-error="textError"
    :error="error"
    :items-per-page="100"
    :disable-selected-options="disableSelectedOptions"
    :required="required"
    :service="apiService"
    :disabled="disabled"
    @change="emitSelection"
  >
    <template slot="option" slot-scope="{ option }">
      <div class="flex flex-row items-center -my-1">
        <span class="pr-4 truncate max-w-2/4" :title="option.name">{{ option.name }}</span>
      </div>
    </template>
  </asterix-async-select>
</template>

<script>
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export default {
  name: 'CampaignFilter',
  components: {
    AsterixAsyncSelect,
  },
  props: {
    service: {
      type: Function,
      required: true,
    },
    textError: {
      type: String,
      default: () => '',
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [Object, Array],
      default: () => [],
    },
    disableSelectedOptions: {
      type: Boolean,
      default: () => false,
    },
    clientId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    apiService(params = new QueryParamsBuilder()) {
      params.addFilter('client.id', this.clientId);
      return this.service(params);
    },
    emitSelection({ items }) {
      if (items) {
        items.value = items.id;
      }
      this.$emit('input', items);
      this.$emit('change', items);
    },
  },
};
</script>

<style scoped>
.campaign-filter {
  width: 330px;
}
</style>
