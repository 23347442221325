var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading && !_vm.isLoadingContent
        ? _c("card-form", {
            staticClass: "block-list mt-10",
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Block List")]
                  },
                  proxy: true,
                },
                {
                  key: "form",
                  fn: function () {
                    return [
                      _c("form-row", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Bundle block list" } },
                                    [
                                      _c("sun-select", {
                                        attrs: {
                                          placeholder: "Add bundles",
                                          label: "block-bundle",
                                          "color-tag": "gray",
                                          options: [],
                                          multiple: "",
                                          taggable: "",
                                        },
                                        on: {
                                          tag: function ($event) {
                                            return _vm.addBlockList(
                                              $event,
                                              "bundle"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.offer.bundleBlackList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.offer,
                                              "bundleBlackList",
                                              $$v
                                            )
                                          },
                                          expression: "offer.bundleBlackList",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Domain block list" } },
                                    [
                                      _c("sun-select", {
                                        attrs: {
                                          placeholder: "Add domains",
                                          label: "block-domain",
                                          "color-tag": "gray",
                                          options: [],
                                          multiple: "",
                                          taggable: "",
                                        },
                                        on: {
                                          tag: function ($event) {
                                            return _vm.addBlockList(
                                              $event,
                                              "domain"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.offer.domainBlackList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.offer,
                                              "domainBlackList",
                                              $$v
                                            )
                                          },
                                          expression: "offer.domainBlackList",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          364426246
                        ),
                      }),
                      _c("form-row", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _vm.defaultBlockList.bundleBlockList.length
                                    ? _c(
                                        "sun-alert",
                                        {
                                          attrs: {
                                            dismissible: false,
                                            variant: "warn",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "flex text-sm mb-2",
                                              attrs: { slot: "title" },
                                              slot: "title",
                                            },
                                            [
                                              _vm._v(
                                                "Bundles blocked by default "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "flex flex-wrap",
                                              attrs: { slot: "description" },
                                              slot: "description",
                                            },
                                            _vm._l(
                                              _vm.defaultBlockList
                                                .bundleBlockList,
                                              function (item) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: item.id,
                                                    staticClass:
                                                      "bg-gray-200 rounded-lg text-gray-700 font-bold py-1 text-xs mx-2 mb-2 px-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _vm.defaultBlockList.domainBlockList.length
                                    ? _c(
                                        "sun-alert",
                                        {
                                          attrs: {
                                            dismissible: false,
                                            variant: "warn",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "flex text-sm mb-2",
                                              attrs: { slot: "title" },
                                              slot: "title",
                                            },
                                            [
                                              _vm._v(
                                                "Domains blocked by default "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "flex flex-wrap",
                                              attrs: { slot: "description" },
                                              slot: "description",
                                            },
                                            _vm._l(
                                              _vm.defaultBlockList
                                                .domainBlockList,
                                              function (item) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: item.id,
                                                    staticClass:
                                                      "bg-gray-200 rounded-lg text-gray-700 font-bold py-1 text-xs mx-2 mb-2 px-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1741001478
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              671444904
            ),
          })
        : _c(
            "div",
            [
              _c("card-form-loading", {
                staticClass: "mt-10",
                attrs: { rows: 2 },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }