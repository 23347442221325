<template>
  <div>
    <card-form v-if="!isLoading && !isLoadingContent" class="block-list mt-10">
      <template #title>Block List</template>
      <template #form>
        <form-row>
          <template #left>
            <sun-label-group text="Bundle block list">
              <sun-select
                v-model="offer.bundleBlackList"
                placeholder="Add bundles"
                label="block-bundle"
                color-tag="gray"
                :options="[]"
                multiple
                taggable
                @tag="addBlockList($event, 'bundle')"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Domain block list">
              <sun-select
                v-model="offer.domainBlackList"
                placeholder="Add domains"
                label="block-domain"
                color-tag="gray"
                :options="[]"
                multiple
                taggable
                @tag="addBlockList($event, 'domain')"
              />
            </sun-label-group>
          </template>
        </form-row>
        <form-row>
          <template #left>
            <sun-alert v-if="defaultBlockList.bundleBlockList.length" :dismissible="false" variant="warn">
              <span slot="title" class="flex text-sm mb-2">Bundles blocked by default </span>
              <span slot="description" class="flex flex-wrap">
                <span
                  v-for="item in defaultBlockList.bundleBlockList"
                  :key="item.id"
                  class="bg-gray-200 rounded-lg text-gray-700 font-bold py-1 text-xs mx-2 mb-2 px-2"
                >
                  {{ item.name }}
                </span>
              </span>
            </sun-alert>
          </template>
          <template #right>
            <sun-alert v-if="defaultBlockList.domainBlockList.length" :dismissible="false" variant="warn">
              <span slot="title" class="flex text-sm mb-2">Domains blocked by default </span>
              <span slot="description" class="flex flex-wrap">
                <span
                  v-for="item in defaultBlockList.domainBlockList"
                  :key="item.id"
                  class="bg-gray-200 rounded-lg text-gray-700 font-bold py-1 text-xs mx-2 mb-2 px-2"
                >
                  {{ item.name }}
                </span>
              </span>
            </sun-alert>
          </template>
        </form-row>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading class="mt-10" :rows="2"></card-form-loading>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { getBlockList } from '@/services/modules/ecommerce/blockList';
import { CONTEXT_NS, ACTIVE_CLIENT_KEY } from '@/store/modules/context/keys';
import { BLOCK_TYPES } from '@/model/modules/ecommerce/block/BlockTypes';
import CardFormLoading from '@/components/atoms/CardFormLoading';

export default {
  name: 'OfferBlockList',
  components: {
    CardForm,
    FormRow,
    CardFormLoading,
  },
  props: {
    baseOffer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      offer: null,
      defaultBlockList: {
        bundleBlockList: [],
        domainBlockList: [],
      },
      isLoadingContent: true,
    };
  },
  computed: {
    ...mapState(CONTEXT_NS, {
      activeClient: ACTIVE_CLIENT_KEY,
    }),
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
  },
  async created() {
    this.loadOffer();
    await this.getDefaultBlockList();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    loadOffer() {
      this.offer = this.baseOffer;
    },
    async getDefaultBlockList() {
      try {
        const { data: defaultBlockList } = await getBlockList(this.activeClient.id);
        this.defaultBlockList.bundleBlockList = defaultBlockList.filter(block => block.type === BLOCK_TYPES.BUNDLE);
        this.defaultBlockList.domainBlockList = defaultBlockList.filter(block => block.type === BLOCK_TYPES.DOMAIN);
      } catch {
        this.createToast(Toast.error('Domains blocked by default', 'Could not load blocks by default'));
        return null;
      }
      this.isLoadingContent = false;
    },
    addBlockList(text, list) {
      if (list === 'domain') {
        this.offer.domainBlackList.push(text);
      } else {
        this.offer.bundleBlackList.push(text);
      }
    },
  },
};
</script>
