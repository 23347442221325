var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading && !_vm.isLoadingContent
        ? _c(
            "card-form",
            [
              _c("template", { slot: "title" }, [_vm._v("Basic Info")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Name" } },
                                [
                                  _c("asterix-input", {
                                    attrs: {
                                      id: "name",
                                      type: "text",
                                      name: "name",
                                      "validate-on-blur": "",
                                      minlength: 3,
                                      maxlength: 250,
                                      "text-error": "This field is required.",
                                      error: !!_vm.formErrors.name,
                                      placeholder: "My new offer...",
                                      required: "required",
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.formErrors.name = null
                                      },
                                    },
                                    model: {
                                      value: _vm.offer.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer, "name", $$v)
                                      },
                                      expression: "offer.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Advertiser" } },
                                [
                                  _c("advertiser-filter", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "client-id": _vm.activeClient
                                        ? _vm.activeClient.id
                                        : "",
                                      service: _vm.getAdvertisers,
                                      required: "required",
                                      "text-error": "This field is required.",
                                      error: !!_vm.formErrors.advertiser,
                                      disabled:
                                        _vm.isEditOffer ||
                                        _vm.isAdvertiserInUrl,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.addAdvertiser($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.offer.advertiser,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer, "advertiser", $$v)
                                      },
                                      expression: "offer.advertiser",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4136891872
                    ),
                  }),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Country" } },
                                [
                                  _c("sun-select", {
                                    attrs: {
                                      options: _vm.countries,
                                      "track-by": "id",
                                      label: "name",
                                      required: "required",
                                      "text-error": "This field is required.",
                                      "close-on-select": "",
                                    },
                                    model: {
                                      value: _vm.offer.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer, "country", $$v)
                                      },
                                      expression: "offer.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Status" } },
                                [
                                  _c("status-select", {
                                    attrs: {
                                      status: _vm.status,
                                      "status-options": _vm.statusOptions,
                                    },
                                    on: { change: _vm.updateStatus },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3643419988
                    ),
                  }),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "sun-label-group",
                                    {
                                      staticClass: "w-full mr-2",
                                      attrs: { text: "Start date" },
                                    },
                                    [
                                      _c("sun-date-picker", {
                                        staticClass: "w-full",
                                        attrs: {
                                          value: { startDate: _vm.minDate },
                                          "single-date-picker": "",
                                          "max-date": _vm.maxDate,
                                          "min-date": _vm.yesterday,
                                          name: "startDate",
                                          "class-input":
                                            _vm.offerDateStartClass,
                                          required: "required",
                                          "text-error":
                                            "This field is required.",
                                        },
                                        on: { change: _vm.onNewStartDate },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-full" },
                                    [
                                      _c(
                                        "sun-label-group",
                                        { attrs: { text: "Start time" } },
                                        [
                                          _c("asterix-time-picker", {
                                            attrs: {
                                              "custom-class":
                                                _vm.offerTimeStartClass,
                                            },
                                            on: { change: _vm.onNewStartTime },
                                            model: {
                                              value: _vm.offerTime.start,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.offerTime,
                                                  "start",
                                                  $$v
                                                )
                                              },
                                              expression: "offerTime.start",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "sun-label-group",
                                    {
                                      staticClass: "w-full mr-2",
                                      attrs: { text: "End date" },
                                    },
                                    [
                                      _c("sun-date-picker", {
                                        key: `${_vm.endDateKey}-end-date`,
                                        staticClass: "w-full",
                                        attrs: {
                                          value: { startDate: _vm.maxDate },
                                          "single-date-picker": "",
                                          "min-date": _vm.offer.start,
                                          name: "endDate",
                                          "class-input": _vm.offerDateEndClass,
                                          "text-error":
                                            "This field is required.",
                                        },
                                        on: { change: _vm.onNewEndDate },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "sun-label-group",
                                    {
                                      staticClass: "w-full",
                                      attrs: { text: "End time" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex flex-col w-full" },
                                        [
                                          _c("asterix-time-picker", {
                                            key: `${_vm.endDateKey}-end-time`,
                                            attrs: {
                                              disabled: !_vm.offer.end,
                                              "custom-class":
                                                _vm.offerTimeEndClass,
                                            },
                                            on: { change: _vm.onNewEndTime },
                                            model: {
                                              value: _vm.offerTime.end,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.offerTime,
                                                  "end",
                                                  $$v
                                                )
                                              },
                                              expression: "offerTime.end",
                                            },
                                          }),
                                          _c("sun-error-text", {
                                            attrs: {
                                              "show-error": !_vm.isDateValid,
                                              "text-error":
                                                "End date must be greater than start date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.offer.end
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "absolute bottom-0 right-0 p-2",
                                          attrs: { role: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onResetEndDate.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("close-svg", {
                                            staticClass:
                                              "w-5 h-5 p-1 text-gray-600",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3460227757
                    ),
                  }),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Campaign" } },
                                [
                                  _c("campaign-filter", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "client-id": _vm.activeClient
                                        ? _vm.activeClient.id
                                        : "",
                                      service: _vm.getCampaigns,
                                      disabled: !_vm.isNewOffer,
                                    },
                                    on: { change: _vm.addCampaign },
                                    model: {
                                      value: _vm.offer.campaign,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer, "campaign", $$v)
                                      },
                                      expression: "offer.campaign",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Tags" } },
                                [
                                  _c("sun-select", {
                                    attrs: {
                                      "tag-placeholder": "Add this as new tag",
                                      placeholder: "Search or add a tag",
                                      label: "name",
                                      "track-by": "id",
                                      options: _vm.tagList,
                                      loading: !_vm.tagList,
                                      "text-error": _vm.errorTags,
                                      error: !!_vm.errorTags,
                                      multiple: "",
                                      taggable: "",
                                      "color-tag": "gray",
                                      "add-hex-color": "orange",
                                    },
                                    on: { tag: _vm.addTag },
                                    model: {
                                      value: _vm.offer.tags,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer, "tags", $$v)
                                      },
                                      expression: "offer.tags",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1351659821
                    ),
                  }),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c("asterix-input-and-show", {
                                attrs: {
                                  "label-title": "Pixel code",
                                  value: _vm.offerPixel,
                                  "cursor-not-allowed": !_vm.offerPixel,
                                  disabled: !_vm.offerPixel,
                                },
                                on: {
                                  open: function ($event) {
                                    return _vm.openDialog("offer")
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c("asterix-input-and-show", {
                                attrs: {
                                  "label-title": "Campaign pixel code",
                                  value: _vm.campaignPixel,
                                  "cursor-not-allowed": !_vm.campaignPixel,
                                  disabled: !_vm.campaignPixel,
                                },
                                on: {
                                  open: function ($event) {
                                    return _vm.openDialog("campaign")
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      104050860
                    ),
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _c("div", [_c("card-form-loading", { attrs: { rows: 6 } })], 1),
      _vm.showPixelModal
        ? _c("asterix-modal", {
            attrs: { title: _vm.pixelModalTitle },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "mb-8 mx-2 w-full pr-2" },
                        [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Image" } },
                            [
                              _c("asterix-input-and-copy", {
                                staticClass: "w-full",
                                attrs: {
                                  value: _vm.pixelIMG,
                                  "detail-classes": "mt-0",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "mt-2",
                              attrs: { text: "Javascript" },
                            },
                            [
                              _c("asterix-input-and-copy", {
                                staticClass: "w-full",
                                attrs: {
                                  value: _vm.pixelJS,
                                  "detail-classes": "mt-0",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-end between sm:px-3",
                        },
                        [
                          _c(
                            "sun-button",
                            {
                              staticClass:
                                "custom-p-1 text-sm hover:bg-gray-100",
                              attrs: { variant: "pill", color: "white" },
                              on: {
                                click: function ($event) {
                                  _vm.showPixelModal = false
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3649655053
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }