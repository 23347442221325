<template>
  <div class="mt-10">
    <card-form v-if="!isLoading">
      <template slot="title">URL</template>
      <template slot="form">
        <url-creator
          v-if="offer"
          v-model="offer.url"
          label="Offer URL"
          class="w-full"
          :options="urlCreatorOptions"
          :text-error="urlError"
          :error="!isUrlValid"
          required
          show-details
        >
          <template #info-popover-content>
            <macro-info :macro-descriptions="macroDescriptions" />
          </template>
        </url-creator>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading class="mt-10"></card-form-loading>
    </div>
  </div>
</template>
<script>
import UrlCreator from '@/components/molecules/shared/UrlCreator/UrlCreator';
import CardForm from '@/components/atoms/CardForm';
import CONFIG_MACROS from '@/views/private/modules/ecommerce/publisher/publisherMacros';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import MacroInfo from '@/components/molecules/modules/ecommerce/publisher/MacroInfo';

export default {
  name: 'OfferUrl',
  components: {
    UrlCreator,
    CardForm,
    CardFormLoading,
    MacroInfo,
  },
  props: {
    baseOffer: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    offer: null,
  }),
  computed: {
    urlCreatorOptions: () => CONFIG_MACROS.urlCreatorOptions,
    macroDescriptions: () => CONFIG_MACROS.macroDescriptions,
    isUrlValid() {
      return this.offer.url?.includes('=${EVENT_SESSION_ID}');
    },
    urlError() {
      const warningText = 'The value ${EVENT_SESSION_ID} must be in url.';
      return !this.isUrlValid ? warningText : null;
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
  },
  created() {
    this.loadOffer();
  },
  methods: {
    loadOffer() {
      this.offer = this.baseOffer;
    },
  },
};
</script>
