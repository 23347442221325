/// <reference path="../../../../entities/ecommerce/Advertiser.js" />

import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { TAGS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

export const getResources = () => ({
  [TAGS_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Tag[],meta:EndpointMeta}>}
 */
export async function getTags(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.get(partialUrl, params.buildWithoutPage() || params);
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
