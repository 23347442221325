var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex w-full rounded-md" },
    [
      _c(_vm.statusIcon, {
        tag: "component",
        staticClass: "w-10 h-10 p-1 rounded-l-md shadow",
        class: _vm.statusIconClass,
      }),
      _c("sun-select", {
        attrs: {
          id: "status-select",
          options: _vm.statusOptions,
          "track-by": "value",
          label: "name",
          "close-on-select": "",
          required: "required",
          "text-error": "This field is required.",
        },
        on: { change: _vm.emitStatus },
        model: {
          value: _vm.clonedStatus,
          callback: function ($$v) {
            _vm.clonedStatus = $$v
          },
          expression: "clonedStatus",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }