var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-timepicker", {
    staticClass: "asterix-timepicker",
    class: [{ "cursor-not-allowed": _vm.disabled }, _vm.customClass],
    attrs: {
      value: _vm.value,
      "input-width": "100%",
      format: _vm.format,
      "hide-clear-button": _vm.hideClearButton,
      "close-on-complete": _vm.closeOnComplete,
      disabled: _vm.disabled,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }