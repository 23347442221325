<template>
  <div>
    <card-form v-if="!isLoading" class="mt-10">
      <template slot="title">Payment Method</template>
      <template slot="form">
        <form-row>
          <div class="flex flex-col w-full xl:w-1/3">
            <sun-label-group class="w-full" text="Payment Method">
              <sun-select
                v-model="priceType"
                :disabled="isEdit"
                :options="costTypeOptions"
                label="name"
                track-by="value"
                text-error="This field is required"
                close-on-select
                required="required"
                @change="changePaymentMethod"
              />
            </sun-label-group>
            <sun-label-group v-if="isCPACostType" text="Type of Pixel" class="mt-5 w-full">
              <sun-select
                v-model="pixelType"
                :disabled="isEdit"
                :options="pixelTypeOptions"
                required="required"
                text-error="This field is required"
                label="name"
                track-by="value"
                close-on-select
              >
              </sun-select>
            </sun-label-group>
            <sun-label-group v-if="isCPACostType" text="Unique" class="mt-5 w-full">
              <sun-select
                v-model="uniqueConversion"
                :options="uniqueConversionOptions"
                required="required"
                text-error="This field is required"
                label="name"
                track-by="value"
                close-on-select
              >
              </sun-select>
            </sun-label-group>
          </div>
          <div class="flex flex-col w-full xl:w-2/3 xl:pl-6">
            <p class="flex items-center mb-1">
              <label class="font-bold text-left text-sm mr-1 sun-label"> Payment amount set by </label>
              <span class="mr-1 font-bold text-red-800">*</span>
              <sun-popover
                position="right"
                text-info="Choose the payment amount using one of the following options. Available options depends on the payment method"
              >
                <sun-interrogation-svg class="text-blue-400" />
              </sun-popover>
            </p>

            <fieldset
              id="paymentOpt"
              class="block w-full border rounded"
              :class="{
                'border-red-500': !!costValueTypeError,
                'cursor-not-allowed': isEdit,
              }"
            >
              <div
                class="flex flex-col border-b p-1 xl:flex-row xl:items-center xl:pt-0 pt-2"
                :class="{
                  'hover:bg-gray-200': !isEdit,
                  'cursor-not-allowed': isEdit,
                }"
                @click.stop="onCostPriceClick"
              >
                <label class="flex items-center">
                  <input :disabled="isEdit" :checked="offer.priceValueType === costValueTypes.PRICE" type="radio" />
                  <span class="mx-2 w-32">Price</span>
                </label>
                <sun-label-group text="Price" class="w-full p-2 xl:flex-grow">
                  <currency-selector
                    v-model="priceGross"
                    class="w-full mt-4 sm:mt-0"
                    placeholder="0.00"
                    :default-currency="defaultCurrency"
                    :max-decimals="maxDecimals"
                    text-error="This field is required"
                    :disabled="!paymentPrice"
                    :currency-disabled="!paymentPrice"
                  />
                </sun-label-group>
                <sun-label-group text="Net Price" class="w-full p-2 xl:flex-grow">
                  <currency-selector
                    v-model="priceNet"
                    class="w-full mt-4 sm:mt-0"
                    placeholder="0.00"
                    :default-currency="defaultCurrency"
                    :max-decimals="maxDecimals"
                    text-error="This field is required"
                    :disabled="!paymentPrice"
                    :currency-disabled="!paymentPrice"
                  />
                </sun-label-group>
              </div>
              <div
                class="flex flex-col border-b p-1 xl:flex-row xl:items-center xl:pt-0 pt-2"
                :class="{
                  'hover:bg-gray-200': percentageAvailable,
                  'cursor-not-allowed': !percentageAvailable || isEdit,
                }"
                @click.stop="onCostPercentageClick(!percentageAvailable || isEdit)"
              >
                <label
                  class="flex items-center"
                  :class="{
                    'cursor-not-allowed': !percentageAvailable || isEdit,
                    'cursor-pointer': percentageAvailable && !isEdit,
                  }"
                >
                  <input
                    :disabled="!percentageAvailable || isEdit"
                    type="radio"
                    :checked="offer.priceValueType === costValueTypes.PERCENTAGE"
                  />
                  <span class="mx-2 w-32"> Percent (%) </span>
                </label>
                <sun-label-group text="Commission" class="w-full p-2 xl:flex-grow">
                  <currency-selector
                    v-model="percentageGross"
                    type="number"
                    class="w-full mt-4 sm:mt-0"
                    name="percentage"
                    placeholder="0%"
                    validate-on-blur
                    :error="!!formErrors.percentage"
                    :disabled="!paymentPercentage || !isOfferActive"
                    :default-currency="defaultCurrency"
                    :min="0"
                    :max="100"
                    :maxlength="getPercentageMaxDecimalsLength(costPercentageGross)"
                    text-error="This field is required"
                    @change="formErrors.percentage = null"
                  />
                </sun-label-group>
                <sun-label-group text="Net Commission" class="w-full p-2 xl:flex-grow">
                  <currency-selector
                    v-model="percentageNet"
                    type="number"
                    class="w-full mt-4 sm:mt-0"
                    name="percentage"
                    placeholder="0%"
                    validate-on-blur
                    :error="!!formErrors.percentage"
                    :disabled="!paymentPercentage || !isOfferActive"
                    :default-currency="defaultCurrency"
                    :min="0"
                    :max="100"
                    :maxlength="getPercentageMaxDecimalsLength(costPercentage)"
                    text-error="This field is required"
                    @change="formErrors.percentage = null"
                  />
                </sun-label-group>
              </div>
            </fieldset>
            <SunErrorText :show-error="!!costValueTypeError" :text-error="costValueTypeError"></SunErrorText>
          </div>
        </form-row>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading class="mt-10" :rows="2"></card-form-loading>
    </div>
  </div>
</template>
<script>
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import TYPE_PIXEL_OPTIONS, {
  TYPE_PIXELS,
  UNIQUE_CONVERSION_OPTIONS,
} from '@/model/modules/ecommerce/offer/TypePixelOptions';
import COST_TYPE_OPTIONS, { COST_TYPES } from '@/model/modules/ecommerce/offer/CostTypeOptions';
import COST_VALUE_TYPES from '@/model/modules/ecommerce/offer/costValueTypes';
import CONFIG from '@/components/molecules/modules/ecommerce/offer/form/payment/config';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import CardFormLoading from '@/components/atoms/CardFormLoading';

export default {
  name: 'OfferPaymentMethod',
  components: {
    CardForm,
    FormRow,
    CurrencySelector,
    CardFormLoading,
  },
  props: {
    baseOffer: {
      type: Object,
      default: null,
    },
    viewMode: {
      type: String,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      offer: null,
      costTypeOptions: COST_TYPE_OPTIONS,
      costValueTypes: COST_VALUE_TYPES,
      pixelTypeOptions: TYPE_PIXEL_OPTIONS,
      uniqueConversionOptions: UNIQUE_CONVERSION_OPTIONS,
      costValueTypeError: null,
      defaultCurrency: 'EUR',
      costPercentage: null,
      costPercentageGross: null,
      formErrors: {
        name: '',
        advertiser: null,
        url: null,
        percentage: null,
        currency: null,
      },
      maxDecimals: 6,
      percentageMaxDecimals: 4,
    };
  },
  computed: {
    isEdit() {
      return this.viewMode === 'edit';
    },
    percentageAvailable() {
      const priceOnlyOptions = COST_TYPE_OPTIONS.filter(value => !value.percentage);
      return priceOnlyOptions.findIndex(opt => opt.value === this.offer?.priceType) === -1;
    },
    paymentPrice() {
      return this.offer.priceValueType === this.costValueTypes.PRICE;
    },
    paymentPercentage() {
      return this.offer.priceValueType === this.costValueTypes.PERCENTAGE;
    },
    isOfferActive() {
      return this.offer.status === CONFIG.OfferStatus.ACTIVE;
    },
    priceType: {
      get() {
        return this.costTypeOptions.find(({ value }) => value === this.offer.priceType);
      },
      set(type) {
        this.offer.priceType = type?.value || null;

        if (this.offer.priceType === COST_TYPES.CPA) {
          this.offer.pixelType = this.pixelTypeOptions.find(pixel => pixel.value === TYPE_PIXELS.IMG).value;
        } else {
          this.offer.priceValueType = this.costValueTypes.PRICE;
          this.offer.pixelType = null;
        }
      },
    },
    priceGross: {
      get() {
        return {
          value: this.paymentPrice ? this.offer.grossPriceValue : null,
          currency: this.offer.currency,
        };
      },
      set({ value, currency }) {
        this.offer.grossPriceValue = value;
        this.offer.currency = currency;
      },
    },
    priceNet: {
      get() {
        return {
          value: this.paymentPrice ? this.offer.netPriceValue : null,
          currency: this.offer.currency,
        };
      },
      set({ value, currency }) {
        this.offer.netPriceValue = value;
        this.offer.currency = currency;
      },
    },
    percentageGross: {
      get() {
        return {
          value: this.paymentPercentage ? this.offer.grossPriceValue : null,
          currency: this.offer.currency,
        };
      },
      set({ value, currency }) {
        this.offer.grossPriceValue = value;
        this.offer.currency = currency;
        this.costValueTypeError = null;
      },
    },
    percentageNet: {
      get() {
        return {
          value: this.paymentPercentage ? this.offer.netPriceValue : null,
          currency: this.offer.currency,
        };
      },
      set({ value, currency }) {
        this.offer.netPriceValue = value;
        this.offer.currency = currency;
        this.costValueTypeError = null;
      },
    },
    pixelType: {
      get() {
        return this.pixelTypeOptions.find(({ value }) => value === this.offer.pixelType);
      },
      set(type) {
        this.offer.pixelType = type?.value || null;
      },
    },
    uniqueConversion: {
      get() {
        return this.uniqueConversionOptions.find(({ value }) => value === this.offer.uniqueConversion);
      },
      set(conversion) {
        this.offer.uniqueConversion = conversion?.value || 0;
      },
    },
    maxlengthPercentageError() {
      return `${this.percentageMaxDecimals} decimals max`;
    },
    isCPACostType() {
      return this.offer.priceType && this.offer.priceType === this.costTypeOptions[1].value;
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
    'offer.priceValueType': function (newValue, previous) {
      if (previous) this.onPaymentChange();
      this.costValueTypeError = null;
    },
  },
  created() {
    this.loadOffer();
  },
  methods: {
    loadOffer() {
      this.offer = this.baseOffer;
    },
    changePaymentMethod() {
      this.offer.uniqueUserDailyCapping = 0;
      this.formErrors.percentage = null;
      this.formErrors.currency = null;
    },
    onCostPercentageClick() {
      if (this.percentageAvailable && !this.isEdit) {
        this.offer.priceValueType = this.costValueTypes.PERCENTAGE;
      }
    },
    onCostPriceClick() {
      if (!this.isEdit) {
        this.offer.priceValueType = this.costValueTypes.PRICE;
      }
    },
    onPaymentChange() {
      this.offer.netPriceValue = null;
      this.offer.grossPriceValue = null;
    },
    validateCostValue() {
      const isValid = !!this.offer.netPriceValue && !!this.offer.grossPriceValue;

      if (isValid) {
        this.costValueTypeError = null;
      } else {
        this.costValueTypeError = 'This field is required';
      }

      return isValid;
    },
    getPercentageMaxDecimalsLength(value) {
      if (value?.toString().includes('.')) {
        const index = value?.toString().indexOf('.');
        return index + this.percentageMaxDecimals + 1;
      }
      return null;
    },
    onUniqueConversionChange(isDisabled) {
      if (isDisabled) return null;
      this.offer.uniqueConversion = !this.offer.uniqueConversion;
    },
  },
};
</script>
<style scoped>
.list-item {
  @apply flex flex-wrap items-baseline border-b p-4 cursor-pointer;
}

.list-item.cursor-not-allowed {
  @apply cursor-not-allowed;
}

.list-item-sm {
  @apply flex-no-wrap;
}
#paymentOpt >>> .sun-label {
  @apply font-semibold text-gray-600 truncate;
}
</style>
<style lang="sass" scoped>
label > input[type="radio"]
  display: none

  + *::before
    content: ""
    display: inline-block
    vertical-align: bottom
    width: 15px
    height: 15px
    margin-right: 0.5rem
    border-radius: 50%
    border-style: solid
    border-width: 1px
    border-color: #ff9c4b

  &:checked + span
    --text-opacity: 1
    color: #4a5568
    color: rgba(74, 85, 104, var(--text-opacity))

  &:checked + *
    color: #ff9c4b

    &::before
      background: radial-gradient(#ff9c4b 0%, #ff9c4b 40%, transparent 50%, transparent)
      border-color: #ff9c4b

  + span
    line-height: 15px

label > input[type="radio"] + *
  display: inline-block
  cursor: pointer

label > input[type="radio"]:disabled + *
  cursor: not-allowed
</style>
