var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "card-form",
            { staticClass: "mt-10" },
            [
              _c("template", { slot: "title" }, [_vm._v("Capping")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                {
                                  staticClass: "w-full",
                                  attrs: { text: "Offer Limit" },
                                },
                                [
                                  _c("asterix-input", {
                                    attrs: {
                                      type: "number",
                                      min: 0,
                                      name: "totalCapping",
                                      placeholder: "1",
                                      "auto-validate": "",
                                    },
                                    model: {
                                      value: _vm.offer.cap.total,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.offer.cap, "total", $$v)
                                      },
                                      expression: "offer.cap.total",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.isPaymentCpc
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    {
                                      staticClass: "w-full",
                                      attrs: { text: "Frequency" },
                                    },
                                    [
                                      _c("sun-select", {
                                        attrs: {
                                          value: _vm.offerFrecuencyCPC,
                                          name: "uniqueUserDailyCapping",
                                          options: _vm.numOptions,
                                          "add-hex-color": "orange",
                                          required: "required",
                                          "text-error":
                                            _vm.i18nErrors.selectOption,
                                        },
                                        on: { change: _vm.frecuencyCpcChanged },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _c("div", [_c("card-form-loading", { staticClass: "mt-10" })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }