import { api } from '.';
import { BLOCK_LIST_RESOURCE } from '@/services/keys';

/**
 * @param {String} blockId
 * @return {Promise<{string}>}
 */
export async function deleteBlock(blockId) {
  const partialUrl = api.createUrl({
    [BLOCK_LIST_RESOURCE]: blockId,
  });
  const {
    data: { blockList: id },
  } = await api.delete(partialUrl);
  return id;
}
