var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sun-label-group", { attrs: { text: _vm.labelTitle } }, [
    _c("div", { staticClass: "flex w-full flex-col" }, [
      _c(
        "div",
        { staticClass: "flex w-full" },
        [
          _c("sun-input", {
            attrs: {
              disabled: "",
              value: _vm.value || "N/A",
              "class-input": "shadow-r-none border-r-none rounded-r-none",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "button",
      {
        staticClass:
          "hover:bg-gray-400 action-button align-bottom p-2 shadow border py-2 px-3 rounded h-10 border-gray-300 rounded-l-none shadow-l-none border-l-none",
        class: { "cursor-not-allowed": _vm.cursorNotAllowed },
        attrs: { type: "button", disabled: _vm.disabled },
        on: {
          click: function ($event) {
            return _vm.openDialog()
          },
        },
      },
      [_c("eye-svg", { staticClass: "w-4 h-4" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }