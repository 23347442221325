/// <reference path="../../../../entities/ecommerce/Tag.js" />
import { api } from '.';
import { TAGS_RESOURCE } from '@/services/keys';

export const getResources = tagId => ({
  [TAGS_RESOURCE]: tagId,
});

/**
 *
 * @param {Tag} tag
 * @returns {Promise.<string>}
 */
export async function createTag(tag) {
  const partialUrl = api.createUrl(getResources(tag.id));
  const {
    data: { id },
  } = await api.create(partialUrl, tag.payload());
  return id;
}
