export default function createPixelJSTag(src) {
  return `<script language="javascript" type="text/javascript">
  (function () {
    var img_url = "${src}" ;
    var img = document.createElement("img");
    var root_elem = document.getElementsByTagName("body")[0];
    img.src = img_url;
    root_elem.appendChild(img)
  })()
</script>`;
}
