<template>
  <div>
    <card-form v-if="!isLoading" class="mt-10">
      <template slot="title">Capping</template>
      <template slot="form">
        <form-row>
          <template #left>
            <sun-label-group text="Offer Limit" class="w-full">
              <asterix-input
                v-model="offer.cap.total"
                type="number"
                :min="0"
                name="totalCapping"
                placeholder="1"
                auto-validate
              />
            </sun-label-group>
          </template>
          <template v-if="isPaymentCpc" #right>
            <sun-label-group text="Frequency" class="w-full">
              <sun-select
                :value="offerFrecuencyCPC"
                name="uniqueUserDailyCapping"
                :options="numOptions"
                add-hex-color="orange"
                required="required"
                :text-error="i18nErrors.selectOption"
                @change="frecuencyCpcChanged"
              />
            </sun-label-group>
          </template>
        </form-row>
      </template>
    </card-form>
    <div v-else>
      <card-form-loading class="mt-10"></card-form-loading>
    </div>
  </div>
</template>
<script>
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import { ERRORS } from '@/i18n/forms/errors';

export default {
  name: 'OfferCapping',
  components: {
    CardForm,
    FormRow,
    AsterixInput,
    CardFormLoading,
  },
  props: {
    baseOffer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isPaymentCpc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offer: null,
      numOptions: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      i18nErrors: ERRORS.en,
    };
  },
  computed: {
    offerFrecuencyCPC() {
      return this.offer?.uniqueUserDailyCapping?.toString() || '0';
    },
  },
  watch: {
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('change', newOffer);
      },
    },
    baseOffer: {
      deep: true,
      handler(newOffer) {
        this.offer = newOffer;
      },
    },
  },
  created() {
    this.loadOffer();
  },
  methods: {
    loadOffer() {
      this.offer = this.baseOffer;
    },
    frecuencyCpcChanged({ value }) {
      const number = parseInt(value, 10);
      this.offer.uniqueUserDailyCapping = number || 0;
    },
  },
};
</script>
