<template>
  <div class="flex w-full rounded-md">
    <component :is="statusIcon" class="w-10 h-10 p-1 rounded-l-md shadow" :class="statusIconClass" />
    <sun-select
      id="status-select"
      v-model="clonedStatus"
      :options="statusOptions"
      track-by="value"
      label="name"
      close-on-select
      required="required"
      text-error="This field is required."
      @change="emitStatus"
    />
  </div>
</template>
<script>
import PlaySvg from '@/components/icons/PlaySvg';
import PauseSvg from '@/components/icons/PauseSvg';
import StopSvg from '@/components/icons/StopSvg';
import ExclamationSvg from '@/components/icons/ExclamationSvg';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'StatusSelect',
  components: {
    PlaySvg,
    PauseSvg,
    StopSvg,
    ExclamationSvg,
  },
  props: {
    status: {
      type: [String, Array, Object],
      default: () => '',
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clonedStatus: null,
      STATUS_OPTIONS: {
        ACTIVE: 'ACTIVE',
        PAUSED: 'PAUSED',
        FINISHED: 'FINISHED',
      },
    };
  },
  computed: {
    statusIcon() {
      switch (this.clonedStatus?.id) {
        case this.STATUS_OPTIONS.ACTIVE:
          return PlaySvg;
        case this.STATUS_OPTIONS.PAUSED:
          return PauseSvg;
        case this.STATUS_OPTIONS.FINISHED:
          return StopSvg;
        default:
          return ExclamationSvg;
      }
    },
    statusIconClass() {
      switch (this.clonedStatus?.id) {
        case this.STATUS_OPTIONS.ACTIVE:
          return 'bg-green-300 text-green-600';
        case this.STATUS_OPTIONS.PAUSED:
          return 'bg-yellow-300 text-yellow-600';
        case this.STATUS_OPTIONS.FINISHED:
          return 'bg-red-300 text-red-600';
        default:
          return 'bg-red-300';
      }
    },
  },
  created() {
    this.clonedStatus = deepClone(this.status);
  },
  methods: {
    emitStatus(status) {
      this.$emit('change', status.value);
    },
  },
};
</script>
<style scoped>
::v-deep #status-select .multiselect__content .multiselect__element:nth-child(1) .multiselect__option--highlight,
::v-deep
  #status-select
  .multiselect__content
  .multiselect__element:nth-child(1)
  .multiselect__option--highlight::after {
  background-color: #48bb78 !important;
}

::v-deep #status-select .multiselect__content .multiselect__element:nth-child(2) .multiselect__option--highlight,
::v-deep
  #status-select
  .multiselect__content
  .multiselect__element:nth-child(2)
  .multiselect__option--highlight::after {
  background-color: #f6e05e !important;
}

::v-deep #status-select .multiselect__content .multiselect__element:nth-child(3) .multiselect__option--highlight,
::v-deep
  #status-select
  .multiselect__content
  .multiselect__element:nth-child(3)
  .multiselect__option--highlight::after {
  background-color: #f56565 !important;
}

::v-deep #status-select .multiselect__tags {
  @apply border-l-0 rounded-l-none;
}
</style>
