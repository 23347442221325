<template>
  <div>
    <sun-form v-if="offer" @submit="submitForm" @keypress.enter="submitForm">
      <offer-basic-info
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        @change="changeOffer($event)"
      ></offer-basic-info>
      <offer-url
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        @change="changeOffer($event)"
      ></offer-url>
      <offer-payment-method
        ref="payment"
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        @change="changeOffer($event)"
      ></offer-payment-method>
      <offer-attribution
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        @change="changeOffer($event)"
      ></offer-attribution>
      <offer-capping
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        :is-payment-cpc="isPaymentCPC"
        @change="changeOffer($event)"
      ></offer-capping>
      <offer-block-list
        :base-offer="offer"
        :view-mode="viewMode"
        :is-loading="isLoadingContent || !offer"
        @change="changeOffer($event)"
      ></offer-block-list>

      <div class="flex mt-12">
        <save-button id="offer-submit" :loading="isSaving" :text="buttonText" />
      </div>
    </sun-form>
    <div v-else>
      <card-form-loading :rows="6"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading :rows="2" class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading :rows="2" class="mt-10"></card-form-loading>
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/atoms/SaveButton';
import { deepClone } from '@/utils/deepClone';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import { dateCompare } from '@/model/shared/SortOption';
import OfferBasicInfo from '@/components/molecules/modules/ecommerce/offer/form/basicInfo/BasicInfo';
import OfferUrl from '@/components/molecules/modules/ecommerce/offer/form/url/OfferUrl.vue';
import OfferPaymentMethod from '@/components/molecules/modules/ecommerce/offer/form/payment/PaymentMethod.vue';
import OfferAttribution from '@/components/molecules/modules/ecommerce/offer/form/attribution/OfferAttribution.vue';
import OfferCapping from '@/components/molecules/modules/ecommerce/offer/form/capping/OfferCapping.vue';
import OfferBlockList from '@/components/molecules/modules/ecommerce/offer/form/block/OfferBlockList.vue';
import { COST_TYPES } from '@/model/modules/ecommerce/offer/CostTypeOptions';

export default {
  name: 'BasicInfo',
  components: {
    OfferBasicInfo,
    OfferUrl,
    OfferPaymentMethod,
    OfferAttribution,
    OfferCapping,
    OfferBlockList,
    SaveButton,
    CardFormLoading,
  },
  props: {
    /** @type Offer */
    baseOffer: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    viewMode: {
      type: String,
      default: () => null,
    },
    updatedOffer: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    isLoadingContent: true,
    hasError: false,
    formErrors: {
      name: '',
      advertiser: null,
      url: null,
      percentage: null,
      currency: null,
    },
    offer: null,
    isSaving: false,
  }),
  computed: {
    isDateValid() {
      const datePassed = dateCompare(this.offer.start, this.offer.end) < 0;
      return datePassed || !this.offer.end;
    },
    isDuplicate() {
      return this.viewMode === 'duplicate';
    },
    isEdit() {
      return this.viewMode === 'edit';
    },
    buttonText() {
      if (this.isDuplicate) {
        return 'Duplicate';
      }
      return this.isEdit ? 'Save' : 'Create';
    },
    isPaymentCPC() {
      return this.offer.priceType === COST_TYPES.CPC;
    },
  },
  watch: {
    isLoading(newLoading) {
      this.isSaving = newLoading;
    },
    baseOffer() {
      this.loadOffer();
    },
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.$emit('update-offer', newOffer);
      },
    },
  },
  created() {
    this.isLoadingContent = true;
    this.loadOffer();
    this.isLoadingContent = false;
  },
  methods: {
    changeOffer(newOffer) {
      this.offer = newOffer;
    },
    loadOffer() {
      this.offer = deepClone(this.updatedOffer);
    },

    capProgressiveChange(type, event) {
      this.offer.capping[type] = event.value !== null;
    },

    submitForm(form) {
      const isValid = this.customFormValidation(form);
      if (isValid && this.isDateValid) {
        if (this.isDuplicate) {
          this.$emit('duplicate', this.offer);
        } else {
          this.$emit('update', this.offer);
        }
      }
    },
    customFormValidation(form) {
      // Very very very ugly fix to solve sun-form not being updated
      delete form.form.percentage;
      const validUrl = !!this.offer?.url && this.offer?.url !== '';
      const allFormItemsValid = isFormValid(form) && validUrl;
      const paymentValid = this.$refs.payment.validateCostValue();
      return allFormItemsValid && paymentValid;
    },
  },
};

function isFormValid(form) {
  return (
    Object.values(form.form)
      .map(item => item?.validation?.isValid ?? true)
      .indexOf(false) === -1
  );
}
</script>

<style scoped>
.list-item {
  @apply flex flex-wrap items-baseline border-b p-4 cursor-pointer;
}

.list-item.cursor-not-allowed {
  @apply cursor-not-allowed;
}

.list-item-sm {
  @apply flex-no-wrap;
}

::v-deep .block-list .multiselect__option {
  @apply hidden;
}
::v-deep .block-list .multiselect__select {
  @apply hidden;
}
::v-deep .block-list .multiselect__tags input {
  @apply mb-0;
}
</style>

<style lang="sass" scoped>
label > input[type="radio"]
  display: none

  + *::before
    content: ""
    display: inline-block
    vertical-align: bottom
    width: 15px
    height: 15px
    margin-right: 0.5rem
    border-radius: 50%
    border-style: solid
    border-width: 1px
    border-color: #ff9c4b

  &:checked + span
    --text-opacity: 1
    color: #4a5568
    color: rgba(74, 85, 104, var(--text-opacity))

  &:checked + *
    color: #ff9c4b

    &::before
      background: radial-gradient(#ff9c4b 0%, #ff9c4b 40%, transparent 50%, transparent)
      border-color: #ff9c4b

  + span
    line-height: 15px

label > input[type="radio"] + *
  display: inline-block
  cursor: pointer

label > input[type="radio"]:disabled + *
  cursor: not-allowed
</style>
