import { api } from '.';
import { BLOCK_LIST_RESOURCE } from '@/services/keys';

/**
 *
 * @param {string} clientId
 * @param {BlockedElement} newBlock
 * @return {Promise<{string}>}
 */
export async function createBlock(clientId, newBlock) {
  const partitialUrl = api.createUrl({
    [BLOCK_LIST_RESOURCE]: undefined,
  });
  const elementToBlock = newBlock.payload();
  elementToBlock.clientId = clientId;
  const {
    data: { blockList: id },
  } = await api.create(partitialUrl, elementToBlock);
  return id;
}
