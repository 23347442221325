var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.offer
        ? _c(
            "sun-form",
            {
              on: {
                submit: _vm.submitForm,
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("offer-basic-info", {
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c("offer-url", {
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c("offer-payment-method", {
                ref: "payment",
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c("offer-attribution", {
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c("offer-capping", {
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                  "is-payment-cpc": _vm.isPaymentCPC,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c("offer-block-list", {
                attrs: {
                  "base-offer": _vm.offer,
                  "view-mode": _vm.viewMode,
                  "is-loading": _vm.isLoadingContent || !_vm.offer,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeOffer($event)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "flex mt-12" },
                [
                  _c("save-button", {
                    attrs: {
                      id: "offer-submit",
                      loading: _vm.isSaving,
                      text: _vm.buttonText,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("card-form-loading", { attrs: { rows: 6 } }),
              _c("card-form-loading", { staticClass: "mt-10" }),
              _c("card-form-loading", {
                staticClass: "mt-10",
                attrs: { rows: 2 },
              }),
              _c("card-form-loading", { staticClass: "mt-10" }),
              _c("card-form-loading", {
                staticClass: "mt-10",
                attrs: { rows: 2 },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }